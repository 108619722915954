html, body {
	font-size: $font-size-base;
	font-family: $font-main;
	color: $font-color;
	overflow-x: hidden;
}

a {
	color: $main-link;
	transition: all 300ms ease-in-out;
	text-decoration: none;

	&:hover {
		color: $main-link-hover;
	}
}

.bold {
	font-weight: bold;
}

.btn {
	padding: 13px 20px;
	border-radius: 20px;
	background: $main-red;
	color: #fff;
	font-weight: normal;
	transition: all 300ms ease-in-out;
	display: inline-block;

	&:hover {
		background: $second-red;
		color: #fff;
		text-decoration: none;
	}
}

.position-relative {
	position: relative;
}

.line {
	background: $main-blue;
	height: 10px;
}

.h-20 {
	height: 20px;
}

.fs-36 {
	font-size: 36px;
	line-height: 43px;
}

.bg-grey {
	background: $main-bg;
}

section {
	padding: 60px 0;
}

#header {
	.lang {
		a {
			display: inline-block;
			padding: 15px;
			text-decoration: none;
		}

		.active {
			color: #92AAB0;
			text-decoration: line-through;
		}
	}

	padding: 20px 0 0 0;

	.logo {
		padding: 35px 0 0 0;

		img {
			max-width: 368px;
		}
	}

	.date {
		margin-top: 20px;
		color: $main-red;
		font-size: 24px;
		font-weight: bold;
	}

	h1 {
		font-size: 24px;
		font-weight: normal;
		text-align: center;
		margin: 30px 0;
	}
}

.feature {
	.fs-36 {
		padding: 20px 0;
	}

	span {
		color: $main-red;
	}
}

#numbers {
	.item {
		width: 255px;
		height: 255px;
		border-radius: 50%;
		background: none;
		border: 1px dashed $main-blue;
		color: #fff;
		margin: 15px;

		.inner {
			width: 240px;
			height: 240px;
			background: $main-blue;
			border-radius: 50%;

			.info {
				width: 200px;
				height: 200px;
				border-radius: 50%;
				background: $second-blue;
			}
		}

		.number {
			color: $main-red;
			font-size: 36px;
			margin-bottom: 15px;
			font-weight: 900;
		}

		.desc {
			text-align: center;
		}
	}
}

#calendar {
	h2 {
		font-weight: normal;
		font-size: 24px;
		margin-bottom: 30px;
	}

	ul {
		margin: 0;
		padding: 0;

		li {
			padding: 5px 0;

			span {
				&:last-child {
					color: $main-red;
					font-weight: bold;
				}
			}

			hr {
				border-top: 1px dashed;
				margin: 0;
				margin: 12px 5px;
				line-height: 24px;
			}
		}
	}
}

@media (max-width: 768px) {
	#calendar {
		ul {
			li {
				span {
					&:last-child {
						max-width: 25%;
					}
				}
			}
		}
	}
}

#producer {
	background: url("/img/bg-producer@2x.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	height: 500px;
	font-size: 21px;
	text-align: center;
	font-weight: bold;

	span {
		color: $main-red;
	}

	h2 {
		font-size: 24px;
		margin-bottom: 30px;
	}

	.btn {
		margin-top: 60px;
	}

	&:before {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		content: ' ';
		background: rgba(255, 255, 255, 0.78);
	}
}

#buyer {
	position: relative;
	background: url("/img/bg-search@2x.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 500px;
	font-size: 21px;
	color: #fff;
	text-align: center;
	font-weight: bold;

	span {
		color: $main-red;
	}

	.btn {
		margin-top: 60px;
	}

	h2 {
		font-size: 24px;
		margin-bottom: 30px;
	}

	&:before {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		content: ' ';
		background: rgba(95, 95, 95, 0.78);
	}
}


#schedule {
	h2 {
		text-align: center;
		margin-bottom: 45px;
		font-weight: normal;
		font-size: 24px;
	}

	ul {
		margin: 0;
		padding: 0;

		&.session {
			padding-left: 60px;
			margin-left: 30px;
			border-left: 1px dashed $main-red;
		}


		li.title {
			color: $font-color;
			font-weight: bold;
			list-style: none;
			position: relative;
			margin-bottom: 25px;
			padding-top: 5px;

			//&:last-child {
			//	.wrapper {
			//		z-index: 999;
			//
			//		&:after {
			//			content: ' ';
			//			position: absolute;
			//			background: #fff;
			//			height: 200px;
			//			width: 2px;
			//			bottom: 0;
			//			top: 0;
			//			right: 0;
			//			left: 0;
			//			margin-left: 27px;
			//			margin-top: 55px;
			//		}
			//	}
			//}

			.text {
				margin-bottom: 10px;
			}

			.wrapper {
				width: 55px;
				height: 55px;
				border-radius: 50%;
				background: #fff;
				color: #fff;
				position: absolute;
				top: 0;
				left: 0;
				margin-left: -88px;
			}

			.counter {
				width: 51px;
				height: 51px;
				border-radius: 50%;
				border: 1px dashed $main-red;

				.inner {
					width: 47px;
					height: 47px;
					background: $main-red;
					border-radius: 50%;

					.info {
						width: 39px;
						height: 39px;
						border-radius: 50%;
						background: $second-red;
					}
				}

				.number {
					color: $main-red;
					font-size: 24px;
					font-weight: 900;
				}
			}
		}

		li {
			span {
				&:first-child {
					color: $main-red;
					font-weight: bold;
					white-space: nowrap;
				}

				&:last-child {
					color: $font-color;
					font-weight: normal;
					width: 80%;
				}
			}

			hr {
				border-top: 1px dashed;
				margin: 0;
				margin: 12px 5px;
				line-height: 24px;
			}
		}
	}
}

#ufexpo {

	.place {
		margin-top: 30px;
		font-size: 21px;
		color: $main-blue;
	}

	.date {
		font-size: 21px;
		color: $main-blue;
		font-weight: bold;
		margin-bottom: 15px;
	}
}

footer {
	padding: 60px 0;

	h3 {
		color: $main-blue;
		font-size: 18px;
		font-weight: bold;
	}

	.info {
		padding: 30px 0;
	}

	.mail {
		text-align: right;

		img {
			height: 200px;
			width: 340px;
			object-fit: cover;
			object-position: center;
		}
	}
}