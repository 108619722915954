@import "reboot";
@import "config";
@import "placeholders";
@import "bootstrap-grid";
@import "flex";
@import "order";
@import "utilities";

.img-fluid {
	width: 100%;
	height: auto;
}
